@font-face {
    font-family: 'Bebas Neue';
    src: url('./BebasNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('./NunitoSans_10pt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
