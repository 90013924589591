.titleMainPageAlarms {
    color: black;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    margin-top: 1%;
    margin-left: 1%;
    text-align: left;
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflow if text exceeds width */
}

.contentWrapperAlarms {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items at the start */
    align-items: flex-start; /* Align items at the start */
    background-color: white;
    padding: 1%; /* Increase padding to ensure content has space */
    border-radius: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    margin-left: 3%;
    width: 90%; /* Increase width to occupy more space */
    margin-top: 1%;
}

.titleMainPageAlarms span {
    border-bottom: 1px solid #757575;
    padding: 2px;
}

.titleSmallAlarms {
    color: black;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    border-radius: 15px;
    margin-top: 1%;
    text-align: left;
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflow if text exceeds width */
}

.alarm-levels-container {
    margin-left: 0; /* Remove left margin */
    width: 100%; /* Ensure it takes full width */
}

.button-container {
    margin-left: 0; /* Ensure buttons align properly */
    margin-top: 10px;
}
