.chartWS {
    display: flex;
    grid-template-columns: 10fr 10fr;
    overflow-x: auto;
    overscroll-behavior: contain;
    max-height: 50vh;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2%;
}
.sensingGraphsMain{
    display: grid;
    grid-template-rows: 85% 15%;
}

.chartSoil {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    overflow-x: auto;
    overscroll-behavior-y: contain;
    max-height: 50vh;
}

.chartSoil::-webkit-scrollbar {
    display: none
}
.spacingSensing {
    height: 20px;
    font-size: 18px;
    margin-top: 5%;
    font-weight: 700;
    font-family: 'Open Sans';
    text-align: left;
    align-items: flex-start;
    justify-content: left;
    margin-bottom: 3%;
    align-self: flex-start; /* Ensure the element aligns to the start of the flex container */
    margin-left: 5%;
    color: #A6CE39;
}

.sensingTitleUp{
    font-size: 22px;
    margin-top: 2%;
    font-weight: 700;
    font-family: 'Open Sans';

    background-color: #A6CE39; /* Background color similar to the button */
  color: white; /* Text color */
  font-family: 'Nunito Sans', sans-serif; /* Font family */
  font-size: 20px; /* Font size */
  font-weight: bold; /* Font weight */
  text-align: center; /* Center the text inside the div */
  padding: 10px 20px; /* Padding for the div */
  border-radius: 8px; /* Border radius to round the corners */
  display: inline-block; /* Ensure the element does not take the full width */
  

    
}

.parentContainer {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
     /* Ensure the parent takes the full height of its container */
  }


.chartSoilInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}

.lineChartCont {
    width: 100%;
    height: auto;
    /* Adjust height as needed */
    box-sizing: border-box;
}

.lineChartCont>* {
    width: auto;
    height: 100%;
}

.chartWS::-webkit-scrollbar {
    display: none
}

.chartWSinner {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.grid-containerVerticalSensing {
    display: grid;
    grid-template-rows: 2fr 3fr;
}

.weatherStationPanel {
    margin-top: 1%;
    margin-bottom: 0%;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 1fr 0.1fr 8fr;
}

.weatherStationSelected {
    display: grid;
    grid-template-rows: 1.5fr 3fr 8fr 2fr 8fr 1fr;
}

.soilMoistureSelected {
    display: grid;
    grid-template-rows: 3fr 4fr 5fr 1.3fr 5fr 1.3fr 5fr 1.3fr 6fr 1.3fr 5fr 0.2fr;
    gap: 7px;
}

.WSboxLayer {
    display: grid;
    grid-template-columns: 1fr 12fr 1fr 12fr 1fr;
}

.soilBoxLayer {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr 10fr 1fr 10fr 1fr;
}

.WSboxInner {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(172, 170, 170, 0.9);
    display: grid;
    place-items: center;
    height: 100%;
    justify-content: 'center'

}

.lineChartCont {
    background-color: "#afababe";
}

/* Add this CSS to your CSS file */
.custom-dot-marker {
    width: 20px;
    /* Adjust width and height to your preference */
    height: 20px;
    background-color: green;
    /* Adjust background color as needed */
    border-radius: 50%;
    /* To make it circular */
}

.dot-marker {
    width: 100%;
    height: 100%;
}

.gg-data {
    transform: scale(var(--ggs,1))
  }
  .gg-data,
  .gg-data::after,
  .gg-data::before {
    box-sizing: border-box;
    position: relative;
    display: block;
    border: 2px solid;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    
  }
  .gg-data::after,
  .gg-data::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    top: 2px;
    left: 2px
  }
  .gg-data::after {
    background: linear-gradient( to left,
        currentColor 8px,transparent 0)
        no-repeat bottom center/2px 8px;
    width: 22px;
    height: 22px;
    top: -6px;
    left: -6px
  }
  .gg-data,
  .gg-data::after {
    border-top-color: transparent;
    border-bottom-color: transparent
  }


  .gg-edit-highlight {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 16px;
    height: 16px;
    border: 2px solid transparent;
    box-shadow: 0 0 0 2px;
    border-radius: 100px;
    overflow: hidden;
  
}
.gg-edit-highlight::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 14px;
    top: -1px;
    background:
        repeating-linear-gradient( to bottom,
            currentColor, currentColor 2px,
            transparent 0px, transparent 3px)
}
.gg-edit-highlight::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 14px;
    right: 0;
    background: currentColor
}

.responsive-chart-containerNew {
    display: flex;
    flex-direction: column;
    width: 94%;
    height: 400px; /* Set an explicit height */
    
}