.mainSectionTitlePivot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.contentWrapperSectors {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items at the start */
    align-items: flex-start; /* Align items at the start */
    background-color: white;
    padding: 1%; /* Increase padding to ensure content has space */
    border-radius: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    width: 90%; /* Adjust width as needed */
    margin-top: 1%;
    margin-left: 3%;
}

.titleMainPageSectors {
    color: black;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    margin-left: 1%;
    margin-right: 30%;
    margin-top: 1%;
    text-align: left;
    justify-content: flex-start;
}

.three-column-container-sectors {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    gap: 20px; /* Adjust spacing between columns */
    margin-top: 20px; /* Adjust top margin */
}

.column {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust spacing between items in each column */
}

.sectors-item {
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Align items vertically center */
    margin-bottom: 10px; /* Add margin between sectors-items */
}

.sectors-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: black;
    text-align: left; /* Align label text to the left */
    white-space: nowrap; /* Prevent label text from wrapping */
    overflow: hidden; /* Hide overflow if label text is too long */
    text-overflow: ellipsis; /* Display ellipsis if text overflows */
    width: 180px; /* Set a fixed width for labels */
    margin-right: 10px; /* Add margin to separate from input */
}

.input-box-sectors {
    width: 150px; /* Set a fixed width for the input boxes */
    height: 20px; /* Set height to 20px as requested */
    border: none;
    background-color: #D9D9D9;
    border-radius: 3px;
    padding: 5px; /* Adjust padding inside input box */
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: black;
}

.map-container {
    /* Adjust styles for your map container */
    width: 100%; /* Ensure map container spans the full width */
}

.map-container img {
    width: 100%; /* Ensure map image fills its container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Add border radius if needed */
}

