.Login_page{
    display: grid;
    width: 100%;
    height: 100%;
    position: fixed;
    display:flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background: rgb(166,206,57);
background: linear-gradient(90deg, rgba(166,206,57,1) 0%, rgba(32,130,164,1) 100%);
    flex-direction:  column;
  
   
    
  }
  
  .Login_page_first_element {
  
    display:grid;
    grid-template-columns: 0.1fr 5px 120px;
    font-size: 28px;
  }

  .logo_login_page{
    height:20%;
    width:auto;
    align-items: center;
 
  }

  /*@media only screen and (max-width: 600px) {*/
    @media (max-width: 600px){
    .logo_login_page {
      height: 90px;
      width: auto;
      background-color: light green;
    } 
  }

  .font-link_robota {
    font-family: 'Varela Round', sans-serif;
    color: white;
  }

  .usertag{
    height:48px;
    width:48px;
  }

  .loginPage_Inner{
    display: grid;
    grid-template-columns:1fr 1fr;
    margin-left: 1%;
   
  }

  .forgot_pass{
      margin-top: 5%;
      margin-left: 5%;
      color: white;
  }

 

.login_button{
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 12px;
      
}

.button {
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
  }

.forgot_pass_register_now{
    margin-top: 5%;
    margin-left: 15%;
    color: white;
}

.welcomeback{
    color: white;
}