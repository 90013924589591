/* Sidebar layout adjustments */
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F2F2F2; /* Background color */
  color: white; /* Text color */
  height: 100vh; /* Full height */
  transition: width 0.3s ease;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

/* Adjusting the width for open and closed states */
.sidebar.closed {
  width: 80px;
}

.sidebar.open {
  width: 250px;
}

.sidebar-icons {
  display: flex;
  flex-direction: column;  /* Stack icons vertically */
  align-items: center;     /* Horizontally center the icons */
  margin-top: 20px; 
  width: 100%;             /* Ensure it takes full width */
}

.menu-icon {
  margin-left: auto;       /* Push the menu icon to the center horizontally */
  margin-right: auto;      /* Push the menu icon to the center horizontally */
  margin-top: 10px;        /* Adjust the margin from the top */
}

/* Space between icons */
.nav-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Smaller gap between icons */
  width: 100%;
  margin-top: 20px; /* Start a bit below the menu icon */
}

.nav-icons .MuiIconButton-root {
  color: black; /* Make the icons black */
}

/* ListItem in the Drawer */
.drawerContent {
  width: 250px;
  padding: 0px 20px; /* Remove top padding, keep side padding */
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 0 0; /* Adjust padding for the close button */
}

.testLayout{
  background-color: red;
  color: white;
}