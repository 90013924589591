.sattPageMainDivUp {
  display: grid;
  grid-template-columns:  21fr  8fr ;
  /* Divide the grid into three equal columns */
  grid-gap: 20px;
  /* Add some gap between grid items */
  background-color: #F2F2F2;
}

.WSboxInnerSatellite{
      display: grid;
      
      height: 100%;
      
      grid-template-rows: 1fr 0.1fr 1fr

}

.WSboxLayerSatellite {
  display: grid;
  grid-template-columns: 2fr 12fr 2fr 12fr 2fr;
}


.satPageLeftMainUpNew {
  display: grid;
  grid-template-rows: 0.1fr 2fr 0.1fr  ;
  gap: 0px;  
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 0%;
  margin-left: 0px;
  
  /*     grid-template-rows: 1fr 2fr 1fr 50fr 10fr 1fr; */

}
.satellitePageLeftGraph{
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-left: 0px;
  justify-content: 'center'; 
  align-items: center; 
  display: grid;
  
}
.lineChartWrapper{
  width: 95%;
  height: auto;
  /* Adjust height as needed */
  box-sizing: border-box;
margin-bottom: 2%;
display: flex;
justify-content: center;
align-items: center;
  max-height: 350px;
}
.notificationsWrapper{
  width: 100%;
  
  /* Adjust height as needed */
  box-sizing: border-box;
margin-bottom: 2%;
display: flex;
justify-content: center;
align-items: center;
  
}
.satellitePageLeftMainDiv{
  display: grid;
  grid-template-rows: 1fr  1fr 0.05fr;
  gap: 20px;
  
}
.satellitePageRightMainDiv{
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  margin-right: 20px;
}

.mapViewBatuSat {
  max-height: 700px;
  /* Adjust margin top as needed */
}

.sateTitle {
  align-items: center;
  text-align: center;
  font-size: 22px;
  font-family: "Open Sans";
  font-weight: 700;
}

.gradient-line-container {
  display: flex;
  align-items: center;
  /* Vertically centers the gradient line */
  justify-content: center;
  /* Horizontally centers the gradient line */
  position: relative;
  margin-bottom: 3%;
  margin-top: 2%;
  
}

.label {
  position: absolute;
  top: 8;
}

.gradient-line {
  margin-top: 0%;
  
  width: 90%;
  /* Adjust the width as per your requirement */
  height: 10px;
  /* This will be the thickness of your line */
  position: relative;
  background: linear-gradient(to right,
          rgba(215, 25, 28, 1) 0%,
          rgba(223, 55, 42, 1) 5%,
          rgba(230, 85, 56, 1) 10%,
          rgba(238, 114, 69, 1) 15%,
          rgba(245, 144, 83, 1) 20%,
          rgba(252, 171, 98, 1) 25%,
          rgba(253, 189, 116, 1) 30%,
          rgba(254, 206, 134, 1) 35%,
          rgba(254, 223, 153, 1) 40%,
          rgba(254, 238, 171, 1) 45%,
          rgba(250, 249, 185, 1) 50%,
          rgba(237, 247, 184, 1) 55%,
          rgba(221, 241, 180, 1) 60%,
          rgba(205, 235, 175, 1) 65%,
          rgba(187, 227, 170, 1) 70%,
          rgba(169, 219, 166, 1) 75%,
          rgba(145, 203, 169, 1) 80%,
          rgba(120, 185, 173, 1) 85%,
          rgba(94, 167, 177, 1) 90%,
          rgba(69, 149, 182, 1) 95%,
          rgba(43, 131, 186, 1) 100%);
  /* 'to right' ensures the gradient flows from left (red) to right (green) */
}

.grid-containerVerticalUp {

  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 0%;
 
  max-height: 75%;

  /*    display: grid;
  grid-template-rows: 1fr 0.01fr 3fr;
  max-height: 100%;
  margin-top: 4%; */
}

.satBoxUp {
 
  display: grid;
  grid-template-rows: 1fr 0.1fr 6fr 0.1fr;
  max-height: 220px;
}

.satBoxGraph {
  margin-top: 5%;
 
  margin-left: 1%;
  margin-right: 1%;
}


.grid-container-info {
  display: grid;
  grid-template-rows: 1fr 5fr 1fr 5fr 1fr 5fr 1fr;
  margin-left: 20%;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.grid-container-info-title {

  font-size: 24px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 5%;
}

.satelliteSelectedUp {
  display: grid;
  grid-template-rows: 1fr 6fr 2fr 6fr 2fr 6fr 1fr;
}

.satWdiget {
  display: grid;
  grid-template-rows:  6fr 0.5fr 6fr ;
}


.lineChartContUp {
  width: 85%;
  height: auto;
  /* Adjust height as needed */
  box-sizing: border-box;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}


.satelliteTitle {
  display: grid;
  grid-template-rows: 1fr 14fr;
  background-color: #F2F2F2;
}


.titleSatPage {
  color: black;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 20px;
  border-radius: 15px;
  display: flex;
  margin-left: 0%;
  margin-right: 30%;
  margin-top: 1%;
  text-align: left;
  /* Aligns the text within the flex container */
  justify-content: flex-start;
  /* Ensures flex items are aligned to the left */
}


.satellite-container {
  position: relative;
}

.titleSatPage {
  color: black;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  /* Center arrow vertically */
  margin-left: 3%;
  margin-right: 30%;
  margin-top: 2%;
  cursor: pointer;
  /* Indicate it's clickable */
  text-align: left;
  justify-content: flex-start;
  position: relative;
  /* Ensure the dropdown aligns relative to this element */
}

.titleSatPage .arrow {
  margin-left: 30px;
  /* Push arrow to the far right */
  font-size: 12px;
  /* Smaller size for the arrow */
  transition: transform 0.3s ease;
}

.titleSatPage .arrow.open {
  transform: rotate(180deg);
  /* Rotate arrow when open */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  /* Position below the title */
  left: 3%;
  /* Align with the title */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  z-index: 9999;
  /*higher z-index makes it appear in front */
  width: 800px;
  /* Set a fixed width */
}

.dropdown-item {
  padding: 10px;
  font-family: "Open Sans";
  font-size: 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.WStitle {
  color: white;
  margin-left: 4%;
  font-size: 28px;
  background-color: #aeaeb6;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 2%;
}

.sattImaDropDown {
  display: grid;
  grid-template-columns: 2% 22.5% 2% 22.5% 2% 22.5% 2% 22.5% 2%;
  margin-top: 2.4%;
  font-family: 'Open Sans', sans-serif;
  /* Apply Open Sans font */
  margin-bottom: 2%;
}



.mapControl {
  margin-left: 2%;
  margin-right: 2%;
  
}


.modal-overlay {
  position: fixed; /* Ensures modal takes full screen and stays fixed */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
  z-index: 9999; /* Places modal above all other content */
  display: flex;
  justify-content: center;
  align-items: center; /* Centers modal vertically and horizontally */
  margin: 0;
  padding: 0;
}

/* Modal Content: Centered Box */
.modal {
  background-color: #fff; /* Modal content background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 600px; /* Restrict maximum width */
  max-height: 80vh; /* Restrict modal height */
  overflow-y: auto; /* Scrollable content if modal exceeds height */
  box-sizing: border-box; /* Ensures padding is included in width */
  position: relative;
}

/* Modal Close Button */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.modal-close-button:hover {
  color: #ff0000;
}

.modal-content {
  text-align: center; /* Aligns the content in the center */
  margin-top: 10px;
}
.settings-box {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;

  width: 100%;
  height: 100%;
  overflow: auto;
  
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(5, 250px);
}


.settingPop{
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  width: 100%;
}


.custom-width {
  width: 100%;
  /* Adjust the percentage as needed */
}

.settingSattDiv {
  margin-top: 2%;
}
.iconWrap{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.iconWrap:hover {
  color: green;
}


.sattSettings {
  display: grid;
  grid-template-columns: 9fr 1fr;
  margin-top: 4%;
}

.sattImageLeaflatMapCompare{
  display: grid;
  grid-template-columns:49.8% 0.1% 49.8% ;
  margin-left: 4%;
  margin-right: 4%;
}

.sattImageLeaflatMap{
  margin-left: 4%;
  margin-right: 4%;
  
}
.sattImageLeaflatMapCompare{
  display: grid;
  grid-template-columns:49.8% 0.1% 49.8% ;
  margin-left: 4%;
  margin-right: 4%;
}
.advanceDate{
  display: grid;
  grid-template-columns:40% 50% ;
  font-size: small;
}
.compareView_selections{
  margin-top: 4%;
  margin-left: auto;
  margin-right: auto;
  width: 80% /* add your desired width here */;
  
}
.compareView_selectionsDate{
  margin-top: 4%;
  margin-left: auto;
  margin-right: auto;
  width: 80% /* add your desired width here */;
  
}

.sattImageCompareSelectDropDown{
  display: grid;
  grid-template-columns:45% 10% 45% ;
  margin-left: 10%;
}

.statusCalender{
  display: grid;
  grid-template-columns:49% 1% 49% ;
}


.satPageMainLayout {
  display: grid;
  gap: 10px; /* Space between grid items */
  padding: 20px;
  grid-template-columns: 60% 40%; /* Defines three columns: 48%, 2%, 48% */
  width: 100%; /* Ensures it spans the full width of the parent */
  height: 100%; /* Ensures it spans the full height of the parent */
  box-sizing: border-box; /* Ensures padding is included in the element's dimensions */
}

.satPageLeftLayout{
  display: grid;
  gap: 10px; /* Space between grid items */
  grid-template-rows: 5% 55% 40%; /* Defines three columns: 48%, 2%, 48% */
  width: 100%; /* Ensures it spans the full width of the parent */
  height: 100%; /* Ensures it spans the full height of the parent */
  box-sizing: border-box; /* Ensures padding is included in the element's dimensions */
}
.satPageRightLayout{
  display: grid;
  gap: 10px; /* Space between grid items */
  grid-template-rows: 5% 32% 62%; /* Defines three columns: 48%, 2%, 48% */
  width: 100%; /* Ensures it spans the full width of the parent */
  height: 100%; /* Ensures it spans the full height of the parent */
  box-sizing: border-box; /* Ensures padding is included in the element's dimensions */
}

.statusSat{
  display: grid;
  gap: 10px; /* Space between grid items */
  padding: 20px;
  grid-template-rows: 50% 50%; /* Defines three columns: 48%, 2%, 48% */
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /* background-color: lightblue; Temporary for debugging */
}
.statusSatUp{
  display: grid;
  gap: 10px; /* Space between grid items */
  grid-template-rows: 50% 50%; /* Defines three columns: 48%, 2%, 48% */
 
}
.statusSatUpHorizontal{
  display: grid;
  gap: 4%; /* Space between grid items */
  grid-template-columns: 33% 33% 33% ; /* Defines three columns: 48%, 2%, 48% */
  margin-left: 1%;
 
}
.notificationsSat{
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: lightblue; /* Temporary for debugging */
}

.irrigationSchSat{
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: grid;
  gap: 6%; /* Space between grid items */
  grid-template-columns: 50% 44% ; /* Defines three columns: 48%, 2%, 48% */
}

.irrigationSchSatVertical{
  display: grid;
  gap: 1px; /* Space between grid items */
  grid-template-rows: 25% 20% 55%; /* Defines three columns: 48%, 2%, 48% */
  margin-left: 5%;
}
.irrigationSchSatBut{
  display: grid;
  gap: 4%; /* Space between grid items */
  grid-template-columns: 48% 48%; /* Defines three columns: 48%, 2%, 48% */
  margin-top: 2%;
  
}
.dateBoxes{
  display: grid;
  gap: 4%; /* Space between grid items */
  grid-template-rows: 48% 48%; /* Defines three columns: 48%, 2%, 48% */
  margin-bottom: 3%;
  
}
.dateBoxesDetail{
  display: grid;
  gap: 1%; /* Space between grid items */
  grid-template-columns: 13% 13% 13% 13% 13% 13% 13% ; 
  
  
}
.dateBoxesDetails{
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  background-color: #2082A4;
  font-weight: bold;
  color: white;
  
  
}
.dateBoxesDetailsOpt{
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  background-color: white;
  font-weight: bold;
  color: gray;
  
  
}
.timeBox{
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 2%;
  margin-right: 4%;
  margin-bottom: 1%;
  display: grid;
  grid-template-columns: 5% 15% 20% 20% 40%;
}

.shifts{
  display: grid;
  gap: 1%; /* Space between grid items */
  grid-template-rows: 32% 32% 32%; /* Defines three columns: 48%, 2%, 48% */
  margin-bottom: 1.5%;
  
}
.shiftsDetail{
  display: grid;
  grid-template-columns: 10% 22% 22% 22%;
  gap: 5%;
}


.notifications {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%; /* Match the grid row height */
}

.sattPageNotificationCustom{
  border-radius: 10px;
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  margin-top: 6px;
  display: grid;
  grid-template-rows: 10% 90%;
  margin-bottom: 1.8%;
}
.sattPageNotTitlePos{
  display: grid;
  grid-template-columns: 35% 30% 35%;
}
.sattPageNotTitle {
  border-radius: 10px;
  background-color: #A6CE39;
  margin-top: 3%;
  color: white; /* White text color */
  font-size: 20px; /* Adjust font size as needed */
  font-weight: 600; /* Slightly bold text for visibility */
  text-align: center; /* Center text horizontally */
  display: flex; /* Flexbox for vertical centering */
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  
  box-sizing: border-box; /* Include padding in size */

  font-family: 'Nunito Sans',
  
}