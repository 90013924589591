body {
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
  }
  
  .todo-app {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .add-task-section {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .task-input {
    width: 60%;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .add-btn, .download-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .download-btn {
    background-color: #2196F3;
  }
  
  .add-btn:hover, .download-btn:hover {
    opacity: 0.9;
  }
  
  .columns {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .column {
    flex: 1;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .column-title {
    text-align: center;
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .task-item {
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .todo-item {
    border-left: 4px solid #FF9800;
  }
  
  .processing-item {
    border-left: 4px solid #FFEB3B;
  }
  
  .done-item {
    border-left: 4px solid #4CAF50;
  }
  
  .status-btn {
    padding: 5px 10px;
    border: none;
    background-color: #2196F3;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .status-btn:hover {
    opacity: 0.9;
  }
  