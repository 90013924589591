.row {
    display: flex;
    align-items: center; /* Vertically center items */
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.columnSectors {
    flex: 1; /* Equal width for each column */
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center; /* Align items vertically within each column */
}

.columnSectors input[type="text"] {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    background-color: #D9D9D9;
    border: none;
    border-radius: 3px;
}

.columnSectors select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    background-color: #757575; /* Background color for the dropdown */
    color: white; /* Text color for the dropdown */
    border: none;
    border-radius: 3px;
    -webkit-appearance: none; /* Remove default arrow */
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"%3E%3Cpath d="M7 10l5 5 5-5z"%3E%3C/path%3E%3C/svg%3E'); /* Fallback for older browsers */
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"%3E%3Cpath d="M7 10l5 5 5-5z"%3E%3C/path%3E%3C/svg%3E'), url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"%3E%3Cpath d="M7 10l5 5 5-5z"%3E%3C/path%3E%3C/svg%3E'); /* Use the icon */
    background-repeat: no-repeat;
    background-position: right 8px center; /* Adjust the position of the icon */
    padding-right: 32px; /* Ensure space for the icon */
    padding-left: 8px; /* Add padding to align text properly */
    padding-top: 12px;
    padding-bottom: 12px;
}

.icon-button-sectors {
    background-color: #A6CE39;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 6px; /* Rounded corners */
}

.icon-button-sectors svg {
    font-size: 24px; /* Adjust the size of the icon if necessary */
}
