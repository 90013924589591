.mapFieldPage{
    margin-top: 2%;
    margin-left: 2%;
    height: 600px;
    width: auto;
  }
  .titleFarmPage{
    color :white;
    margin-left: 4%;
    font-size: 28px;
    background-color: #0E8140;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 20px;
    
    
  }