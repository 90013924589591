.navbar {
    background-color: #0E8140;
    height: 64px;
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 25fr 2fr 2.5fr 0.5fr;
}

.navbar-link {
    color: white;
    text-decoration: none;
    padding: 20px 30px 20px 30px;
    font-family: 'Nunito Sans';
    margin-top: 20px;
}

.navbar-link:hover {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /*background-color: lightblue; */
}

.navbar-texts {
    padding: 20px;
}

.logo {
    display: flex;
    align-items: center;  
}
.logoText {
    display: flex;
    align-items: center; 
    transform: scale(1.5); 
}


.logo img {
    width: 50px;
    /* Adjust the width as needed */
    height: auto;
    margin-left: 20px;
    margin-right: 10px;
    /* Add some spacing if desired */
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5)); 
}
.logoText img {
    width: 50px;
    /* Adjust the width as needed */
    height: auto;
    margin-left: 20px;
    margin-right: 10px;
    /* Add some spacing if desired */
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5)); 
}

.logoText:hover{
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5)); 
}

.logo:hover{
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5)); 
}

.logOutIcon {
    display: flex;            /* Use Flexbox */
    align-items: center;       /* Vertically center the content */
    justify-content: center;   /* Horizontally center the content if needed */
  }