.subFields{
  
    color: green;
    
    margin-left: 8%;
    
  }

  .subDevices{
    color:yellowgreen;
    margin-left: 14%;
  }

  .denemeSidebarTtls{
    display: inline-flex;
    
    vertical-align: middle; /* This can help with vertical alignment if the Link or surrounding elements are inline-block or inline */
    
  
  }
  