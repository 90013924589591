.mainSectionTitlePivot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.contentWrapperEndgun {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items at the start */
    align-items: flex-start; /* Align items at the start */
    background-color: white;
    padding: 1%; /* Increase padding to ensure content has space */
    border-radius: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    margin-left: 3%;
    width: 90%; /* Increase width to occupy more space */
    margin-top: 1%;
}

.titleMainPageEndgun {
    color: black;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    margin-left: 1%;
    margin-right: 30%;
    margin-top: 1%;
    text-align: left;
    justify-content: flex-start;
}

.endgun-title {
    border-bottom: 1px solid #757575;
    display: inline-block;
    padding: 2px 0; /* Adjust vertical padding to align text properly */
    width: fit-content; /* Let the width be automatically determined by content */
    margin-bottom: 20px; /* Add space below the title */
}

.button-container {
    display: flex;
    gap: 10px; /* Adjusts space between buttons */
    margin-left: 5%;

}

.titleMainPageEndgunTable {
    color: black;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    margin-left: 1%;
    margin-right: 30%;
    margin-top: 1%; /* Add some space above */
    text-align: left;
    justify-content: flex-start;
}

.endgun-table-title {
    border-bottom: 1px solid #757575;
    display: inline-block;
    width: fit-content; /* Adjust width to fit content */
    padding: 2px 0; /* Adjust vertical padding */
    margin-bottom: 20px; /* Add space below the title */
    margin-top: 5%;
}

.three-column-table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; /* Adjusts space between cells */
    margin-left: 3%; /* Aligns with the left margin of the title */
    width: 80%;
}

.table-cell {
    padding: 10px;
    background-color: #D9D9D9; /* Set background color */
    text-align: center; /* Center text inside cells */
    border: none; /* Remove border for all cells */
}

.title-cell {
    padding: 10px;
    background-color: #D9D9D9; /* Set background color */
    text-align: center; /* Center text inside cells */
    border: none; /* Remove border for title cells */
    font-family: "Open Sans", sans-serif;
    font-weight: 600; /* semibold */
    font-size: 14px;
    white-space: nowrap; /* Prevent text from wrapping */
}

.three-column-table > .title-cell:first-child,
.three-column-table > div:nth-child(3n + 1) {
    background-color: transparent; /* Remove background color for first column */
}

.three-column-table > div:nth-child(-n+3) {
    background-color: transparent; /* Remove background color for first row */
}
