/* App.css */

.mainSectionTitle {
    display: grid;
    grid-template-rows: 1fr 14fr;
    background-color: #F2F2F2;
}

.titleMainPage {
    color: black;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    display: flex;
    margin-left: 3%;
    margin-right: 30%;
    margin-top: 1%;
    text-align: left; /* Aligns the text within the flex container */
    justify-content: flex-start; /* Ensures flex items are aligned to the left */
}

.grid-container {
    display: grid;
    grid-template-columns: 0.8fr 21fr 0.8fr 14fr 0.8fr;
    /* Divide the grid into three equal columns */
    grid-gap: 1px;
    /* Add some gap between grid items */
    background-color: #F2F2F2;
}

.grid-containerVerticalMain {
    display: grid;
    grid-template-rows: 2fr 3fr;
}

.grid-item {
    grid-column: span 3;
}

.grid-image {  
    width: 200px;
    /* Make the image occupy the entire width of the grid item */
    height: 100px;
    /* Maintain the image's aspect ratio */
    text-align: center;
}

.MainDownLeft {
    display: grid;
    grid-template-rows: 6fr 1fr 6fr 1fr;

}

.notificationPart {
    display: grid;
    grid-template-rows: 13fr 1fr;
}

.MainDownLeftSolarDev {
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.MainDownLeftSolarDevConditional {
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #F6F6F6;
    text-align: center;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 1%;
    font-size: 14px;
}



.MainDownLeftPumps {
    border-radius: 10px;
    display: grid;
    grid-template-columns: 55fr 1fr 55fr;
}

.pumpItemBox {
   
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 30fr 0.1fr 30fr;
    position: relative; /* Necessary for the overlay to be positioned correctly */
    margin-bottom: 0px; /* Add some space between boxes */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(193, 193, 193, 0.7); /* Semi-transparent gray background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 5px;
    /* Add any additional styles for your overlay here */
  }
  
  .overlayText {
    font-size: 1.5em; /* Adjust font size as needed */
    color: white;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    text-align: center;
  }
  

.overlay:hover {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5)); /* Drop shadow on hover */
}



.boxLine {
    background-color: #757575;
}

.pumpItem {
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    border: 1px solid #757575;
    border-radius: 10px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}

.pumpItemInner {
    margin-top: 0%;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 12px;
}

.mapImage {
    height: 250px;
    width: 815px;
    margin-top: 3%;
    border-radius: 15px;
}

.solarDeviceTitle {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.solarDeviceMainTitle {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin-top: 4%;
}

.informPanelTitlesDetail {
    text-align: left;
    margin-left: 10%;
    margin-top: 3%;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.allBoxText {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 8%;
    margin-top: 5%;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.timeStamp {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    margin-top: 10%;
}

.mapWrapMain {
    margin-top: 2%;
    margin-bottom: 2%;
}

.pivotStatusSectionMain {
    margin-top: 4%;
    margin-bottom: 2%;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 1fr 0.01fr 1fr 0.01fr 1fr 0.3fr 4fr 0.3fr;
}

.statusStartStop {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 3fr 1fr 3fr 1fr 3fr 1fr;
}
.statusStartStopName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
}

.pivotStatusDetailTable {
    display: grid;
    grid-template-columns: 0.5fr 10fr 1fr 10fr 0.5fr;
}

.pivotStatusDetailLeft {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

