.grid-container-land {
    display: grid;
    grid-template-rows: 1fr 14fr; /* Creates 4 equal columns */
    gap: 10px; /* Space between grid items */
    padding: 20px;
    width: 100%; /* Full width of the container */
    
  }
  
  .grid-item-title {
    
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 40%;
  }

  .grid-item-main {
    display: grid;
    grid-template-columns: 2fr 1fr; /* Creates 4 equal columns */
    gap: 20px; /* Space between grid items */
    width: 100%; /* Full width of the container */
    
  }
  .grid-item-main-left{
    display: grid;
    grid-template-rows: 1.5fr  1fr  1fr ;
    gap: 20px;
  }
  .homePageMainMapWrap{
    max-height: 800px; 
    min-height: 400px;
  }

  .grid-item-main-right-home{
    display: grid;
    grid-template-rows: 1.5fr 2fr  ;
    gap: 20px;
  }

  .pivotStatusSectionPopUp{
    margin-top: 0%;
    margin-bottom: 0%;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 1fr 0.01fr 1fr 0.01fr 1fr 0.3fr 4fr 0.3fr;
  }

  .pivotStatusSectionPopUpNot{
    margin-top: 0%;
    margin-bottom: 0%;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 1fr 0.01fr 1fr 0.01fr 1fr 0.3fr 4fr 0.3fr;
  }

  .pumpItemInner {
    margin-top: 0%;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 14px;
}

.pumpItem {
  background-color: rgba(255, 255, 255, 0.3);
  text-align: center;
  border: 1px solid #757575;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.SolarItemCenter {
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;
  background-color: #FFFFFF;
  text-align: center;
  border: 1px solid #afabab;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin: 1%;
  font-size: 14px;
}

.statusStartStopNameUp-home {
  display: grid;
  grid-template-columns:  1fr 1fr 1fr 1fr 0.2fr;  
  
}

.timeStampDiv{
  display: grid;
  grid-template-columns: 1.5fr 2.8fr 4.8fr  0.2fr;
  margin-top: 2%;
  
}

