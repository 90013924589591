.pivot-navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    padding: 0.4% 0.8%;
    border-radius: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    margin-left: 0.2%;
    margin-top: 0.3%;
    width: 100%;
}

.pivot-nav-item {
    color: #757575;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    padding: 0.3rem 0.4rem;
    margin: 0 1rem;
    white-space: nowrap;
}

.pivot-nav-item:hover,
.pivot-nav-item.active {
    background-color: #A6CE39;
    border-radius: 5px;
    color: white;
}
