.WSmain{
    height: 100%;
    
  }
  .WStitle{
    color :white;
    margin-left: 4%;
    font-size: 28px;
    background-color: #aeaeb6;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30%;
    margin-right: 30%;
    
    
  }

  .timestamp {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 2%;
    
    color: #0E8140;
    text-align: right;
    font-size: 1.2vh;
  }

  .map_data{
    margin-top: 1%;
    margin-left: 2%;
    display: grid;
    grid-template-columns:1.6fr 1fr;
  }
  .map_data_mobile{
    margin-top: 2%;
    margin-left: 2%;
    z-index: 0;
    
  }

  .mapclass {
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  .weather_map_right {
    display: grid;
    grid-template-rows:45% 10% 45%;
    margin-left: 5%;
  }

  .detail_boxe {
    background-color: rgb(177, 172, 172);
    border-radius: 25px;
    display: grid;
    grid-template-columns:49.5% 1% 49.5%;
  }
  .detail_boxeM {
    background-color: white;
    border-radius: 25px;
    display: grid;
    grid-template-columns:49.5% 1% 49.5%;
    height: 15vh;
  }
  .value_box{
    display: grid;
    grid-template-rows:50% 50%;
    text-align: center;
    font-size: 15px;
  }
  
  .value_boxM{
    display: grid;
    grid-template-rows:50% 50%;
    text-align: center;
    font-size: 15px;
  }

  .weather_icons {
    height: 8vh;
    width: auto;
    margin-top: 20%;
    margin-left: 35%;
    color: orange;
    
  
  }
  .weather_iconsM {
    height: 5vh;
    width: auto;
    margin-top: 10%;
    margin-left: 40%;
    color: orange;
    
  
  }
  .div_line{
    
    display: grid;
    grid-template-rows:10% 70% 10%;
  }
  .div_line_color {
    background-color: #E7E7E8;
  
  }
  .weatherStationGraphs{
  
    height: auto;
    width: 45%;
    display: grid;
    grid-template-columns: 110% 110% ;
    flex-direction: column;
    margin-left: 2%;
  
    
  }
  .responsive-chart-container {
    display: flex;
    flex-direction: column; /* Stack charts vertically by default */
    width: 94%; /* Allow container to fill available space */
  }
  .downloadButton{
    display: flex;
    align-items: center; /* Aligns items vertically center */
    justify-content: flex-start; /* Aligns items to the left */
    width: 100%; /* Ensures the container takes full width */
    margin-left: 75%;
  }