.timeline-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 20px;
    background-color: #f8f8f8;
    white-space: nowrap;
}

.timeline-item {
    display: inline-block;
    position: relative;
    margin: 0 20px;
}

.timeline-content {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 200px; /* Adjust based on your content needs */
}

.timeline-item:after {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px; /* Half of your indicator's width */
    width: 20px; /* Width of the line */
    height: 2px;
    background-color: #333;
}

.timeline-item:first-child:after {
    display: none;
}
