.mainSectionTitlePivot {
    display: grid;
    grid-template-rows: auto 1fr 14fr;
    background-color: #F2F2F2;
    grid-gap: 1px; /* Add gap between rows for better spacing */
}

.sectionTitlePivot {
    display: grid;
    grid-template-rows: auto 1fr 1fr;
    background-color: #28d131;
    grid-gap: 1px; /* Add gap between rows for better spacing */
    
}

.titleMainPagePivot {
    color: black;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    margin-left: 3%;
    margin-right: 20%;
    margin-top: 1%;
    text-align: left; /* Aligns the text within the flex container */
    justify-content: flex-start; /* Ensures flex items are aligned to the left */
    white-space: nowrap; /* HA KISMI ALTA DUSUYORDU TEXTIN. ONU ENGELLEMEK ICIN */
}
.sectionTitleNot{
    min-height: 600px;
}

.titleMainPagePivotDown {
    color: black;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    display: flex;
    margin-left: 3%;
    margin-right: 30%;
    margin-top: 1%;
    margin-bottom: 1%;
    text-align: left; /* Aligns the text within the flex container */
    justify-content: flex-start; /* Ensures flex items are aligned to the left */
}


.grid-container {
    display: grid;
    grid-template-columns: 0.8fr 21fr 0.8fr 14fr 0.8fr;
    /* Divide the grid into three equal columns */
    grid-gap: 1px;
    /* Add some gap between grid items */
    background-color: #F2F2F2;
}

.grid-containerVerticalMain {
    display: grid;
    grid-template-rows: 2fr 3fr;
}

.grid-containerVerticalPivot {
    display: grid;
    grid-template-rows: repeat(5, auto); /* Adjusted to 'auto' for variable row height */
    grid-row-gap: 1px; /* Adjust this value to reduce or increase the vertical gap */
}

.grid-item {
    grid-column: span 3;
}

.grid-image {
    width: 200px;
    /* Make the image occupy the entire width of the grid item */
    height: 100px;
    /* Maintain the image's aspect ratio */
    text-align: center;
}

.MainDownLeftPivot {
    display: grid;
    grid-template-rows: 1fr 10fr 1fr;

}

.notificationPart {
    display: grid;
    grid-template-rows: 13fr 1fr;
}


.SolarItemCenter {
    display: grid;
    grid-template-rows: 1fr 4fr 1fr;
    background-color: #F6F6F6;
    text-align: center;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 1%;
    font-size: 14px;
}


.pumpItemBox {
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 30fr 0.1fr 30fr;
}

.boxLine {
    background-color: #757575;
}

.pumpItem {
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    border: 1px solid #757575;
    border-radius: 10px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}

.pumpItemInner {
    margin-top: 3%;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 14px;
}

.mapImage {
    height: 250px;
    width: 815px;
    margin-top: 3%;
    border-radius: 15px;
}

.solarDeviceTitle {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.solarDeviceMainTitle {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin-top: 4%;
}

.informPanelTitlesDetail {
    text-align: left;
    margin-left: 10%;
    margin-top: 3%;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.allBoxText {
    margin-left: 8%;
    margin-top: 5%;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.timeStamp {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    margin-top: 10%;
}

.mapWrap {
    margin-top: 2%;
    margin-bottom: 2%;
}

.pivotStatusSectionMain {
    margin-top: 4%;
    margin-bottom: 2%;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 1fr 0.01fr 1fr 0.01fr 1fr 0.3fr 4fr 0.3fr;
}

.statusStartStop {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 3fr 1fr 3fr 1fr 3fr 1fr;
}

.pivotStatusDetailTable {
    display: grid;
    grid-template-columns: 0.5fr 50fr 1fr 50fr 0.5fr;
}

.pivotStatusDetailLeft {
    display: grid;
    grid-template-rows: repeat(9, 1fr);
}

.pivotStatusDetailItem {
    display: flex;
    align-items: center;
    padding-left: 5%;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    color: black;
}

.pivotStatusDetailItem:nth-child(odd) {
    background-color: rgba(128, 128, 128, 0.2);
}

.pivotStatusDetailValue {
    margin-left: auto;
    margin-right: 1rem; /* Adjust the space between value and icon */
}

.pivotStatusDetailIcon {
    cursor: pointer;
    font-size: 16px;
}



.pivot-navbarContainer {
    justify-self: start; /* Center align horizontally within the column */
    margin-top: 1%; /* Adjust margin-top for spacing */
    margin-left: 3%; /* Adjust margin-left for spacing */
}