.homePageMainLayout {
    display: grid;
    gap: 10px; /* Space between grid items */
    padding: 20px;
    grid-template-columns: 60% 40%; /* Defines three columns: 48%, 2%, 48% */
    width: 100%; /* Ensures it spans the full width of the parent */
    height: 100%; /* Ensures it spans the full height of the parent */
    box-sizing: border-box; /* Ensures padding is included in the element's dimensions */
  }

  .homePageLeftLayout{
    display: grid;
    gap: 10px; /* Space between grid items */
    grid-template-rows: 5% 55% 20% 25% 1%; /* Defines three columns: 48%, 2%, 48% */
    width: 100%; /* Ensures it spans the full width of the parent */
    height: 100%; /* Ensures it spans the full height of the parent */
    box-sizing: border-box; /* Ensures padding is included in the element's dimensions */
  }

  .homePageRightLayout{
    display: grid;
    gap: 10px; /* Space between grid items */
    grid-template-rows: 5% 55% calc(45% + 20px) 1%; /* Defines three columns: 48%, 2%, 48% */
    width: 100%; /* Ensures it spans the full width of the parent */
    height: 100%; /* Ensures it spans the full height of the parent */
    box-sizing: border-box; /* Ensures padding is included in the element's dimensions */
  }

  .homePageStat{
    margin-top: 0%;
    border-radius: 10px;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-rows: 12% 12% 12% 54%;
    gap: 3.3%;
  }
  .homePageNotificationCustom{
    border-radius: 10px;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    margin-top: 6px;
    display: grid;
    grid-template-rows: 10% 90%;
  }
  .homePageNotTitlePos{
    display: grid;
    grid-template-columns: 35% 30% 35%;
  }
  .homePageNotTitle {
    border-radius: 10px;
    background-color: #A6CE39;
    margin-top: 3%;
    color: white; /* White text color */
    font-size: 20px; /* Adjust font size as needed */
    font-weight: 600; /* Slightly bold text for visibility */
    text-align: center; /* Center text horizontally */
    display: flex; /* Flexbox for vertical centering */
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    
    box-sizing: border-box; /* Include padding in size */

    font-family: 'Nunito Sans',
    
}

  .homePageStatTitle{
    
  }

  .homePageStatNavBot{
    
  }

  .homePageStatBot{
    
  }

  .homePageStatData{
    display: grid;
    grid-template-columns: 0.5fr 10fr 1fr 10fr 0.5fr;
  }

  .statusStartStopNameUpNav {
    display: grid;
    grid-template-columns:  2% 20% 20% 20% 20%;
    gap: 2%;
    
  }
  .statusStartStopNameUp-home {
    display: grid;
    
    grid-template-columns:  2% 20% 20% 20% 20%;
    gap: 2%;
    
  }

  .pivotStatusDetailTableHome {
    display: grid;
    grid-template-columns: 0.5fr 10fr 1fr 10fr 0.5fr;
}

.homePageStatDataDetail{
    display: grid;
    grid-template-rows: 14% 14% 14% 14% 14% 14% 14%;
}