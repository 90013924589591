.pivot-status {
    font-family: Arial, sans-serif;
    padding: 20px;
}

.status-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.status-card h2 {
    margin-top: 20px;
    color: #333;
}

.status-card p {
    margin: 5px 0;
}

.status-card p strong {
    display: inline-block;
    width: 200px;
}
