.mainSectionTitlePivot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.pageLayout {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 20px; /* Space between the columns */
    width: 100%;
    margin-left: 2%;
    margin-top: 1%;
}

.contentWrapperSetup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items at the start */
    align-items: flex-start; /* Align items at the start */
    background-color: white;
    padding: 1%; /* Increase padding to ensure content has space */
    border-radius: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    width: 90%; /* Adjust width as needed */
    margin-top: 1%;
}

.titleMainPageSetup {
    color: black;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    margin-left: 1%;
    margin-right: 30%;
    margin-top: 1%;
    text-align: left;
    justify-content: flex-start;
}

.setup-title {
    border-bottom: 1px solid #757575;
    display: inline-block;
    padding: 2px 0; /* Adjust vertical padding to align text properly */
    width: fit-content; /* Let the width be automatically determined by content */
    margin-bottom: 20px; /* Add space below the title */
}

.two-column-container {
    display: grid;
    grid-template-columns: 300px auto; /* Fixed width for labels, adjust as needed */
    gap: 20px; /* Adjust spacing between setup-items */
    margin-left: 2%;
}

.setup-item {
    display: contents; /* Use CSS Grid, so children take the parent's grid */
}

.setup-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: black;
    text-align: left; /* Align label text to the left */
    padding-right: 20px; /* Add space between label and input */
}

.input-box {
    width: 250px; /* Set a fixed width for the input boxes */
    height: 20px; /* Set height to 20px as requested */
    border: none;
    background-color: #D9D9D9;
    border-radius: 3px;
    padding: 5px; /* Adjust padding inside input box */
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: black;
}

.advanced-setup-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
    margin-left: 40px;
}

.warning-box {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
    position: relative;
}

.warning-text {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: #721c24;
    padding: 0;
    line-height: 1;
}

.mainSectionTitlePivot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.contentWrapperSetup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    padding: 1%;
    border-radius: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    margin-left: 3%;
    width: 90%;
    margin-top: 1%;
}

.titleMainPageSetup {
    color: black;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    margin-left: 1%;
    margin-right: 30%;
    margin-top: 1%;
    text-align: left;
}

.setup-title {
    border-bottom: 1px solid #757575;
    display: inline-block;
    padding: 2px 0;
    width: fit-content;
    margin-bottom: 20px;
}

.table-container {
    margin-top: 20px;
    margin-left: 3%; /* Added left margin of 3% */
}

.custom-table {
    width: 100%;
    border-collapse: separate; /* Use separate border-collapse */
    border-spacing: 1px; /* Set spacing between cells */
}

.custom-table th,
.custom-table td {
    padding: 8px;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}


.custom-select {
    width: 150px; /* Adjusted width for smaller size */
    background-color: #B7B7B7;
    color: white;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    padding: 4px;
    border: none;
    outline: none;
    border-radius: 5px;
}

.change-password-button {
    text-transform: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px; /* Font size changed to 10px */
    font-weight: 600; /* Changed to semibold */
    background-color: #D9D9D9; /* Updated background color */
    color: white;
    padding: 8px 6px; /* Reduced padding for a narrower button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.change-password-button:hover {
    background-color: #C0C0C0; /* Darker shade for hover effect */
    border-color: #555555; /* Border color change on hover */
}
