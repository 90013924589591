.sensingPageMainLayout {
    display: grid;
    gap: 10px;
    /* Space between grid items */
    padding: 20px;
    grid-template-columns: 60% 40%;
    /* Defines three columns: 48%, 2%, 48% */
    width: 100%;
    /* Ensures it spans the full width of the parent */
    height: 100%;
    /* Ensures it spans the full height of the parent */
    box-sizing: border-box;
    /* Ensures padding is included in the element's dimensions */
}

.sensingPageLeftLayout {
    display: grid;
    gap: 10px;
    /* Space between grid items */
    grid-template-rows: 5% 55% 55%  1%;
    /* Defines three columns: 48%, 2%, 48% */
    width: 100%;
    /* Ensures it spans the full width of the parent */
    height: 100%;
    /* Ensures it spans the full height of the parent */
    box-sizing: border-box;
    /* Ensures padding is included in the element's dimensions */
}

.sensingPageRightLayout {
    display: grid;
    gap: 10px;
    /* Space between grid items */
    grid-template-rows: 5% 55% calc(55% ) 1%;
    /* Defines three columns: 48%, 2%, 48% */
    width: 100%;
    /* Ensures it spans the full width of the parent */
    height: 100%;
    /* Ensures it spans the full height of the parent */
    box-sizing: border-box;
    /* Ensures padding is included in the element's dimensions */
}

.sensingGraphsUp {
    border-radius: 10px;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px;
    overflow-x: auto;
    max-height: 50vh;
    
    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.sensingGraphsUp::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}

/* Responsive design for smaller screens */
@media (max-width: 1024px) {
    .sensingGraphsUp {
        grid-template-columns: 100%; /* Switch to one column */
        max-height: none;
    }
}

.chartWSinnerNew {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.downloadButtonNew {
    padding: 12px;
    margin-left: 5%;

    @media (max-width: 1024px) { /* Adjust alignment for small screens */
        margin-left: 0; /* Center align */
        text-align: center;
    }
}

.sensingStat{
    border-radius: 10px;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.sensingPageNotificationCustom{
    border-radius: 10px;
    border: 1px solid #afabab;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    margin-top: 0px;
    display: grid;
    grid-template-rows: 10% 90%;
  }

  .notData{
    margin-top: 2%;
   
  }