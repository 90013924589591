.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.white-icon {
  filter: brightness(0) invert(1);
}


/* Custom style for Leaflet popups */
.leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.5); /* #B7B7B7 with 40% opacity */
  color: #000; /* Text color for readability */
  border-radius: 8px; /* Optional: rounded corners */
  padding: 8px; /* Optional: padding */
  box-shadow: none; /* Optional: remove shadow */
}

.leaflet-popup-tip {
  background: rgba(255, 255, 255, 0.4); /* Match the popup background */
}
/* Style only for popups with the .custom-popup class */
.custom-popup .leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  border-radius: 8px;
  padding: 8px;
}
