.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000; /* Ensure the modal has a high z-index */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-main {
    background: white;
    width: 60%;
    max-width: 800px; /* Optional: to control the maximum width */
    height: 60%;
    max-height: 500px; /* Optional: to control the maximum height */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    position: relative;
    overflow: auto;
    z-index: 10001; /* Ensure the modal content has a high z-index */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none;
    font-size: 1.5rem;
    color: #757575; /* Optional: Adjust icon color */
    z-index: 10002; /* Ensure the close button is on top */
}

.actions {
    display: flex;
    align-items: center;
    gap: 2px; /* Adjust spacing between button and dropdown */
}

.update-button {
    background-color: #A6CE39;
    border: none;
    border-radius: 3px; /* Filleted corners */
    color: white;
    padding: 10px 40px;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    font-family: 'Open Sans', sans-serif; /* Apply Open Sans font */
    font-weight: 600; /* Semibold */
}

.update-button:hover {
    background-color: #8BB92D; /* Darker shade for hover effect */
}

.dropdown-menu {
    padding: 10px;
    border: 1px solid #A6CE39;
    border-radius: 3px;
    background-color: white;
    font-family: 'Open Sans', sans-serif; /* Apply Open Sans font */
    font-weight: 600; /* Semibold */
    font-size: 1rem;
    outline: none;
    cursor: pointer;
}

.display-block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.display-none {
    display: none;
}
