.grid-container-land-irrigation {
    display: grid;
    grid-template-rows: 1fr 18fr;
    /* Creates 4 equal columns */
    gap: 10px;
    /* Space between grid items */
    padding: 20px;
    width: 100%;
    /* Full width of the container */

}

.headerDiv {
    display: grid;
    grid-template-columns: 0.9fr 1fr;
    /* Creates 4 equal columns */
}

.grid-item-title-irrigation {

    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 40%;
    margin-bottom: 0%;
    position: relative;
}

.titleSatPage {
    color: black;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    display: flex;
    margin-left: 3%;
    margin-right: 30%;
    margin-top: 2%;
    text-align: left;
    /* Aligns the text within the flex container */
    justify-content: flex-start;
    /* Ensures flex items are aligned to the left */
}

.dropdown-menu-irrigation {
    position: absolute;
    top: 100%;
    /* Position below the title */
    left: 3%;
    /* Align with the title */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    z-index: 9999;
    /*higher z-index makes it appear in front */
    width: 250px;
    /* Set a fixed width */
}

.dropdown-item-irrigation {
    padding: 10px;
    font-family: "Open Sans";
    font-size: 16px;
    cursor: pointer;
    color: black;
}

.grid-item-main-left-irrigation {
    display: grid;
    grid-template-rows: 1.5fr 2fr;
    gap: 20px;
}

.grid-item-main-right {
    display: grid;
    grid-template-rows: 1.5fr 2fr;
    gap: 20px;
}

.titleMainPagePivotDown {
    color: black;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 18px;
    border-radius: 15px;
    display: flex;
    margin-left: 3%;
    margin-right: 30%;
    margin-top: 1%;
    margin-bottom: 1%;
    text-align: left;
    /* Aligns the text within the flex container */
    justify-content: flex-start;
    /* Ensures flex items are aligned to the left */
}

.pivotStatusDetailTable {
    display: grid;
    grid-template-columns: 0.5fr 50fr 1fr 50fr 0.5fr;
}

.pivotStatusDetailLeft {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
}

.pivotStatusDetailItem {
    display: flex;
    align-items: center;
    padding-left: 5%;
    font-family: 'Helvetica';
    font-size: 13px;
    font-weight: 500;
    color: black;
    min-height: 38px;
}

.pivotStatusDetailItem:nth-child(odd) {
    background-color: rgba(128, 128, 128, 0.2);
}

.pivotStatusDetailValue {
    margin-left: auto;
    margin-right: 1rem;
    /* Adjust the space between value and icon */
}

.pivotStatusDetailValueGPS {
    display: inline-block;              /* Makes it behave like a button */
    padding: 3px 6px;                   /* Adds space inside the button */
    background-color: #2082A4;          /* Background color for button effect */
    color: white;                       /* Text color for contrast */
    border-radius: 4px;                 /* Rounds the corners */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3); /* Adds a subtle shadow */
    font-size: 12px;                    /* Adjust font size for button text */
    font-weight: bold;                  /* Optional: make text bold */
    margin-left: auto;                   /* Adds space between the text and previous element */
    margin-right: 1rem;
    text-align: center;                 /* Centers the text inside the button */
}

.pivotStatusDetailValueOK {
    display: inline-block;              /* Makes it behave like a button */
    padding: 3px 6px;                   /* Adds space inside the button */
    background-color: #87BF59;          /* Background color for button effect */
    color: white;                       /* Text color for contrast */
    border-radius: 4px;                 /* Rounds the corners */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3); /* Adds a subtle shadow */
    font-size: 12px;                    /* Adjust font size for button text */
    font-weight: bold;                  /* Optional: make text bold */
    margin-left: auto;                   /* Adds space between the text and previous element */
    margin-right: 1rem;
    text-align: center;                 /* Centers the text inside the button */
}

.pivotStatusDetailValueDRY {
    display: inline-block;              /* Makes it behave like a button */
    padding: 3px 6px;                   /* Adds space inside the button */
    background-color: white;          /* Background color for button effect */
    color: black;                       /* Text color for contrast */
    border-radius: 4px;                 /* Rounds the corners */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3); /* Adds a subtle shadow */
    font-size: 12px;                    /* Adjust font size for button text */
    font-weight: bold;                  /* Optional: make text bold */
    margin-left: auto;                   /* Adds space between the text and previous element */
    margin-right: 1rem;
    text-align: center;                 /* Centers the text inside the button */
}

.pivotStatusDetailIcon {
    cursor: pointer;
    font-size: 16px;
}

.irrigationStatus {}

.grid-containerVerticalPivot {
    display: grid;
    grid-template-rows: repeat(5, auto);
    /* Adjusted to 'auto' for variable row height */
    grid-row-gap: 1px;
    /* Adjust this value to reduce or increase the vertical gap */
}

.sectionTitlePivot {
    display: grid;
    grid-template-rows: auto 1fr 1fr;
    background-color: #28d131;
    grid-gap: 1px;
    /* Add gap between rows for better spacing */
}
.sectionContainer {
    margin-bottom: 0.3rem;   /* Larger spacing after each section */
}

.pivot-navbarContainer {
    margin-left: auto;
    /* Pushes the container to the right */
    margin-right: 10px;
    /* Adds a 10px gap from the right */
}