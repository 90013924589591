.titleMainPageCalendar {
    color: black;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
    margin-left: 1%;
    margin-right: 30%;
    margin-top: 1%;
    text-align: left;
    justify-content: flex-start;
}

.contentWrapperCalendar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items at the start */
    align-items: flex-start; /* Align items at the start */
    background-color: white;
    padding: 1%; /* Increase padding to ensure content has space */
    border-radius: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    margin-left: 3%;
    width: 90%; /* Increase width to occupy more space */
    margin-top: 1%;
}

.titleMainPageCalendar span {
    border-bottom: 1px solid #757575;
    display: inline-block;
    width: 80%;
    padding: 2px;
}

.controls-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Allows wrapping if needed */
    margin-top: 10px; /* Add some margin between lines  eìburasiii eklendi*/
}

.titleMainPageCalendar select {
    width: 180px;
    margin-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    background-color: #D9D9D9;
    color: white;
    border-radius: 3px;
    border: none;
    padding: 3px;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.checkbox-container input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    background-color: #D9D9D9;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
}

.checkbox-container input[type="checkbox"]:checked::after {
    content: '';
    display: block;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 5px;
}

.checkbox-container label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 12px;
}
