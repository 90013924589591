.alarm-levels-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust spacing between items */
    margin-top: 10px; /* Add top margin as needed */
    margin-left: 3%; 
}

.alarm-levels-checkbox {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust spacing between label and checkboxes */
}

.label-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: black;
    flex: 0 0 200px; /* Fixed width for the label */
    margin-right: 3%;
}

.checkbox-options {
    display: flex;
    gap: 70px; /* Adjust spacing between checkbox groups */
}

.checkbox-option {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust spacing between checkbox and label */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.checkbox-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1.5px solid #757575;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #757575;
}

.checkbox-option.selected .checkbox-circle {
    background-color: #A6CE39; /* Color when selected */
    color: white;
}

.checkbox-option:not(.selected) .checkbox-circle {
    background-color: transparent; /* Empty background for unselected */
}

.checkbox-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #757575;
}
